import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [
  { key: 'notifications', text: 'Notifications', url: '/notifications' }
]

const Notifications = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Notifications Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default Notifications
